export function searchParams() {
  return new URLSearchParams(window.location.search);
}

export const throttle = (func: Function, delay: number) => {
  let prev = 0;
  return (...args: any[]) => {
    let now = new Date().getTime();

    if (now - prev > delay) {
      prev = now;
      return func(...args);
    }
  };
};

export const getWidgetInstance = () => {
  console.log('check url', window.location);
  if (Boolean(~window.location.href.search('/ipr2023'))) return 'ipr2023';
  else return null;
};

export const getLocalLanguage = () => {
  const languages = ['ru', 'en', 'es', 'pt'];
  const browserLanguage = window.navigator.language.substring(0, 2).toLowerCase();
  return languages.includes(browserLanguage) ? browserLanguage : 'en';
};

const locales: { [key: string]: any } = {
  ru: () => import('dayjs/locale/ru'),
  en: () => import('dayjs/locale/en'),
  pt: () => import('dayjs/locale/pt'),
  es: () => import('dayjs/locale/es'),
};

export const loadLocale = async (lang: string) => (locales[lang] || locales.en)?.();
