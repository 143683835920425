import dayjs from 'dayjs';
import * as EmailValidator from 'email-validator';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import Promo from '../../../components/Promo';
import { mainApi, s3urls } from '../../../helpers/api';
import { getUTMCommentForCP } from '../../../helpers/cloudPayments';
import { Button, DateTimeInput, Input, InputContainer, IPlace, PaymentCardPicker, PlaceInput } from '../../../libs';
import { usePricesStore } from '../../../store/usePricesStore';
import { LocationIcon } from '../assets/icons/system';
import { getPaymentResult, getPromoDiscount, hash } from '../helpers/api';
import { Error } from '../helpers/errors';
import { PaymentMethod, paymentMethods } from '../helpers/payments';
import { PaymentData } from '../helpers/types';
import SuccessInfo from './SuccessInfo';
import { PaymentScheme } from '../../../helpers/payments';

// import ReRecaptcha, { TReReCaptchaApi } from '../../../components/ReRecaptcha';

interface FormProps {
  token: string;
  partner: string;
  setError(value: Error): void;
}

export default function Form(props: FormProps) {
  const defaultDate = dayjs().toISOString();
  const defaultTime = dayjs().utc().set('hour', 12).set('minute', 0).set('second', 0).toISOString();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState<string>(defaultDate);
  const [time, setTime] = useState<string>(defaultTime);
  const [place, setPlace] = useState<IPlace | null>(null);

  const [nameMessage, setNameMessage] = useState<string | undefined>();
  const [emailMessage, setEmailMessage] = useState<string | undefined>();
  const [timeMessage, setTimeMessage] = useState<string | undefined>();
  const [dateMessage, setDateMessage] = useState<string | undefined>();
  const [placeMessage, setPlaceMessage] = useState<string | undefined>();

  const [promo, setPromo] = useState('');

  const [promoError, setPromoError] = useState<string | null>(null);
  const [showPromoInput, setShowPromoInput] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(paymentMethods[0]);
  const [discount, setDiscount] = useState<number>(0);

  const [payedStatus, setPayedStatus] = useState<'success' | 'failed'>();

  // const captchaRef = React.useRef<TReReCaptchaApi | null>(null);

  const price = usePricesStore().prices?.finance_capacity || 0;
  const setPrices = usePricesStore().setPrices;

  useEffect(() => {
    setPrices(props.token, props.partner, paymentMethod.country);
  }, [paymentMethod, props.token, props.partner, setPrices]);

  const postfix = <LocationIcon />;

  const isDisabled = useMemo((): boolean => {
    return Boolean(
      nameMessage ||
        !name ||
        emailMessage ||
        !email ||
        timeMessage ||
        !time ||
        dateMessage ||
        !date ||
        placeMessage ||
        !place,
    );
  }, [nameMessage, name, emailMessage, email, timeMessage, time, dateMessage, date, placeMessage, place]);

  const onNameChange = (name: string) => {
    const resultName = name.trim();
    setName(name);
    if (/^[a-z,а-я,ё,\s]*$/i.test(resultName)) {
      setNameMessage(resultName.length === 0 ? 'Имя не может быть пустым' : undefined);
    } else {
      setNameMessage('В имени могут быть только буквы');
    }
  };

  const onEmailChange = (email: string) => {
    setEmail(email);
    setEmailMessage(EmailValidator.validate(email) || email === '' ? undefined : 'Введите корректный email');
  };

  const onDateChange = (isoDate?: string) => {
    const message = 'Нужно ввести дату';
    if (isoDate) {
      setDate(isoDate);
      setDateMessage(
        dayjs(isoDate) < dayjs('1900-01-01')
          ? 'Введите корректную дату'
          : dayjs(isoDate) > dayjs()
            ? 'Введите дату в прошлом'
            : undefined,
      );
    } else {
      setDateMessage(message);
    }
  };

  const onTimeChange = (isoTime?: string) => {
    const message = 'Нужно ввести время';

    if (isoTime) {
      setTime(isoTime);
      setTimeMessage(undefined);
    } else {
      setTimeMessage(message);
    }
  };

  const onPlaceChange = (place: IPlace | string | null) => {
    if (typeof place === 'object') {
      setPlace(place);
      setPlaceMessage(undefined);
    } else {
      setPlace((place) => ({ ...place!, lat: 0, lon: 0 }));
      setPlaceMessage('Выберите город из списка');
    }
  };

  const checkErrorMode = (val: any) => (Boolean(val) ? 'error' : 'normal');

  const onSuccess = (hash: string) => {
    setPayedStatus('success');
  };

  const handleSubmit = async () => {
    // captchaRef.current?.reset();
    // const captchaToken = await captchaRef.current?.executeAsync();

    const data: PaymentData = {
      place: place?.name!,
      lat: place?.lat!,
      lon: place?.lon!,
      name: name,
      date: dayjs.utc(date).format('DD.MM.YYYY'),
      time: dayjs.utc(time).format('HH:mm:ss'),
      partner: props.partner,
      token: props.token,
      email,
      promo,
      country: paymentMethod.country,
      subtype: 'finance_capacity',
      // captchaToken
    };

    getPaymentResult(data, props.setError).then(async (result) => {
      if (result) {
        if (result.promo && result.success) {
          onSuccess(result);
          return;
        }

        const CustomerReceipt = { ...result.receipt, email };
        data.type = 'widget';
        data.cloudPayments = { CustomerReceipt };
        data.createdAt = Date.now();
        data.K8S_NAMESPACE = result.K8S_NAMESPACE;
        data.operation = await hash(JSON.stringify(data));
        data.comment = getUTMCommentForCP();

        Object.assign(result.options, {
          InvoiceId: props.token,
          data,
          email,
          requireEmail: true,
          skin: 'mini',
          type: 'widget',
          country: paymentMethod.country,
        });

        await mainApi.payment(result.options, PaymentScheme.CHARGE, {
          onSuccess,
        });
      }
    });
  };

  const updateDiscount = useCallback(
    debounce(
      (promo: string, email: string) => {
        getPromoDiscount(props.token, promo, email).then((result) => {
          setDiscount(result);
        });
      },
      1000,
      {
        leading: false,
      },
    ),
    [],
  );

  const onSetPromo = (promo: string) => {
    updateDiscount(promo, email);
    setPromo(promo);
  };

  if (payedStatus === 'success') {
    return (
      <Container>
        <Content payedStatus={payedStatus}>
          <SuccessInfo email={email!} />
        </Content>
      </Container>
    );
  }

  const getLinks = () => {
    switch (paymentMethod.country) {
      case 'kz':
      case 'us':
        return (
          <Link href={s3urls.termsOfUseKZ} target="_blank" rel="noreferrer">
            Пользовательское соглашение
          </Link>
        );
      default:
        return (
          <Link href={s3urls.termsOfUseRU} target="_blank" rel="noreferrer">
            Пользовательское соглашение
          </Link>
        );
    }
  };

  return (
    <Container>
      <FormContainer>
        <InputContainer label="Имя" message={nameMessage}>
          <StyledInput
            name="username"
            size="small"
            value={name}
            onChange={onNameChange}
            placeholder="Как Вас зовут?"
            mode={checkErrorMode(nameMessage)}
          />
        </InputContainer>

        <InputContainer label="Почта" message={emailMessage}>
          <StyledInput
            name="email"
            size="small"
            value={email}
            onChange={onEmailChange}
            mode={checkErrorMode(emailMessage)}
            placeholder="Ваш e-mail"
          />
        </InputContainer>

        <InputContainer label="Время рождения" message={timeMessage}>
          <StyledDateTimeInput
            size="small"
            type="time"
            value={time}
            hideIcon={true}
            onChange={onTimeChange}
            onInvalid={onTimeChange}
            mode={checkErrorMode(timeMessage)}
            utcMode
          />
        </InputContainer>

        <InputContainer label="Дата рождения" message={dateMessage}>
          <StyledDateTimeInput
            size="small"
            type="date"
            value={date}
            onChange={onDateChange}
            onInvalid={onDateChange}
            hideIcon={true}
            mode={checkErrorMode(dateMessage)}
            utcMode
          />
        </InputContainer>

        <InputContainer label="Место рождения" message={placeMessage}>
          <StyledPlaceInput
            size="small"
            value={place?.name || ''}
            onSelect={onPlaceChange}
            onChange={onPlaceChange}
            placeholder="Начните вводить место рождения"
            asyncDataFn={mainApi.places.bind(mainApi)}
            asyncDetailFn={mainApi.place.bind(mainApi)}
            mode={checkErrorMode(placeMessage)}
            lang="ru"
            rightIcon={postfix}
          />
        </InputContainer>

        <Promo
          promo={promo}
          showPromoInput={showPromoInput}
          setShowPromoInput={setShowPromoInput}
          setPromo={onSetPromo}
          promoError={promoError}
        />

        <PaymentCardPicker
          items={paymentMethods}
          value={paymentMethod}
          // @ts-ignore
          onChange={setPaymentMethod}
          showInfo={false}
        />

        <StyledButton name="submit-button" color="blue" onClick={handleSubmit} disabled={isDisabled}>
          <ButtonTitle price={price} discount={discount} symbol={paymentMethod.symbol} />
        </StyledButton>
      </FormContainer>

      <Footer>
        Нажимая «Построить», вы принимаете <br />
        {getLinks()}
      </Footer>

      {/* <ReRecaptcha
        size="invisible"
        badge="bottomright"
        ref={captchaRef}
      /> */}
    </Container>
  );
}

const ButtonTitle = ({ price, discount, symbol }: { price: number; discount: number; symbol: string }) => {
  let text = (
    <>
      за {price} {symbol}
    </>
  );
  if (discount === 1) text = <>бесплатно</>;
  else if (discount > 0)
    text = (
      <>
        за {price * (1 - discount)} {symbol} <del style={{ opacity: 0.7 }}>{price} ₽</del>
      </>
    );

  return <>Построить {text}</>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
`;

const Description = styled.div`
  width: 42.5rem;
  max-width: 85%;
  margin-top: 1rem;
  text-align: left;

  .title {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.75rem;
  }

  .subtitle {
    color: var(--text-third);
  }
`;

const FormContainer = styled.div`
  width: 42.5rem;
  max-width: 85%;
  font-size: 0.875rem;
  margin: 2rem 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;

  & > :nth-child(5),
  & > :nth-child(n + 6) {
    grid-column: 1 / 3;
  }

  @media (max-width: 768px) {
    & > :nth-child(1) {
      grid-column: 1 / 3;
    }

    & > :nth-child(2) {
      grid-column: 1 / 3;
    }
  }
`;

const StyledInput = styled(Input)<{ error?: boolean }>`
  ${(props) =>
    props.error &&
    css`
      input {
        border-color: var(--red-color);
      }
    `};

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const StyledDateTimeInput = styled(DateTimeInput)<{ error?: boolean }>`
  ${(props) =>
    props.error &&
    css`
      border-color: var(--red-color);
    `};

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const StyledPlaceInput = styled(PlaceInput)<{ error?: boolean }>`
  /* ${(props) =>
    props.error &&
    css`
      border: 1px solid var(--red-color);
    `};   */

  div {
    top: 25%;
    left: 95%;
  }

  @media (max-width: 768px) {
    font-size: 1rem;

    div {
      top: 25%;
      left: 90%;
    }
  }
`;

const StyledButton = styled(Button)`
  color: white !important;
  background: #4093f4 !important;
  font-size: 1.25rem;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--gray-color);
  font-size: 1rem;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const Link = styled.a`
  /* color: var(--color-blue); */
`;

const Content = styled.div<{ sidebar?: string; payedStatus?: string }>`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  @media (max-width: 1280px) {
    height: 100%;
    ::-webkit-scrollbar {
      width: 0px;
    }
  }

  @media (max-width: 768px) {
    width: 100vw !important;
  }
`;
