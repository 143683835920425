import Form from './components/Form';
import { useEffect, useState } from 'react';
import { Error } from './helpers/errors';
import styled from 'styled-components';
import { NotificationPopup } from '../../libs';
import '../../i18n/i18n';
import { loadLocale } from '../../helpers/utils';
import dayjs from 'dayjs';
import i18n from '../../i18n/i18n';
import AppEmptyPage from '../../components/AppEmptyPage';
import { Loader } from '../../libs';

export default function NatalMapApp({ token, partner }: { token: string; partner: string }) {
  const [error, setError] = useState<Error | null>(null);
  const [translationsInit, setTranslationsInit] = useState(false);

  const initLng = async (lng: string) => {
    await loadLocale(lng);
    dayjs.locale(lng);
    setTranslationsInit(true);
  };

  useEffect(() => {
    i18n.on('loaded', async () => {
      await initLng(i18n.language);
    });
  }, []);

  if (!translationsInit) {
    return (
      <AppEmptyPage>
        <Loader />
      </AppEmptyPage>
    );
  }

  return (
    <Container>
      <Form token={token} partner={partner} setError={(value) => setError(value)} />
      {error && (
        <StyledNotificationPopup
          type={'warning'}
          size={'small'}
          title={error.title}
          // @ts-ignore
          content={error.content}
          onClose={() => setError(null)}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const StyledNotificationPopup = styled(NotificationPopup)`
  & > div {
    border-radius: 0.4rem;
  }

  .title {
    text-align: left;
    margin-top: 2rem;
  }

  .content {
    text-align: left;
    margin-top: 1.5rem;
  }

  @media (max-width: 460px) {
    & > div {
      height: 40%;
    }
  }
`;
