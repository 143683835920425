import React from 'react';
import ReactDOM from 'react-dom';
import App, { WIDGET_TYPE } from './App';
import './style/css/inter.css';
import './style/css/main.css';

export default class ChronosWidget extends HTMLElement {
  constructor() {
    super();
  }

  private token: string = this.getAttribute('token') || '';
  private type: WIDGET_TYPE = (this.getAttribute('type') as WIDGET_TYPE) || 'default';
  private sidebar: string = this.getAttribute('sidebar') || 'default';
  private partner: string = this.getAttribute('partner') || '';
  private staged: boolean = this.getAttribute('isStaged') === 'true' ? true : false;

  connectedCallback() {
    // const root = this.attachShadow({ mode: 'open' });
    const mountPoint = document.createElement('div');
    this.appendChild(mountPoint);

    ReactDOM.render(
      <App token={this.token} partner={this.partner} type={this.type} sidebar={this.sidebar} staged={this.staged} />,
      mountPoint,
    );
  }
}

// init
customElements.define('chronos-widget', ChronosWidget);
