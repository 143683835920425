export interface Error {
  title: string;
  content: string;
}

export const INVALID_EMAIL = 'INVALID_EMAIL';

export const errorInvalidEmail = {
  title: 'Некорректная почта',
  content: 'Вы указали некорректную почту. Обратитесь в поддержку',
};

export const errorFinanceWasGot = {
  title: 'Вы уже получали данный разбор',
  content: 'Посмотрите письмо от chronos.mg в вашей почте',
};

export const errorDefault = {
  title: 'Ошибка',
  content: 'Произошла ошибка. Обратитесь в поддержку',
};
