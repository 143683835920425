import { Button, DateTimeInput, Input, InputContainer, PlaceInput, IPlace, Radio } from '../../../libs';
import dayjs from 'dayjs';
import * as EmailValidator from 'email-validator';
import { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { mainApi, s3urls } from '../../../helpers/api';
import { LocationIcon } from '../assets/icons/system';
import { Error } from '../helpers/errors';
import { getInterpretation, getMap, sendUnisender } from '../helpers/api';
import { MapData } from '../helpers/types';
import NatalMapSunResult from './NatalMapSunResult';
import { useNatalSunMapStore } from '../helpers/store';
import { googleEvent } from '../../../helpers/metrics';

interface FormProps {
  token: string
  partner: string
  setError(value: Error): void
}

export default function Form(props: FormProps) {
  const defaultDate = dayjs().toISOString()
  const defaultTime = dayjs().utc().set('hour', 12).set('minute', 0).set('second', 0).toISOString();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState<string>(defaultDate);
  const [time, setTime] = useState<string>(defaultTime);
  const [place, setPlace] = useState<IPlace | null>(null);

  const [nameMessage, setNameMessage] = useState<string | undefined>();
  const [emailMessage, setEmailMessage] = useState<string | undefined>();
  const [timeMessage, setTimeMessage] = useState<string | undefined>();
  const [dateMessage, setDateMessage] = useState<string | undefined>();
  const [placeMessage, setPlaceMessage] = useState<string | undefined>();

  const [reason, setReason] = useState<'person' | 'astrolog'>('person');

  const {mapImage, setMapImage, interpretation, setInterpretation, setData} = useNatalSunMapStore();

  const postfix = <LocationIcon/>

  const isDisabled = useMemo((): boolean => {
    return Boolean(
      nameMessage || !name ||
      emailMessage || !email ||
      timeMessage || !time ||
      dateMessage || !date ||
      placeMessage || !place
    );
  }, [nameMessage, name, emailMessage, email, timeMessage, time, dateMessage, date, placeMessage, place]);

  const onNameChange = (name: string) => {
    const resultName = name.trim();
    setName(name);
    if (/^[a-z,а-я,ё,\s]*$/i.test(resultName)) {
      setNameMessage(resultName.length === 0 ? 'Имя не может быть пустым' : undefined);
    } else {
      setNameMessage('В имени могут быть только буквы');
    }
  }

  const onEmailChange = (email: string) => {
    setEmail(email);
    setEmailMessage(EmailValidator.validate(email) || email === '' ? undefined : 'Введите корректный email');
  }

  const onDateChange = (isoDate?: string) => {
    const message = 'Нужно ввести дату'
    if (isoDate) {
      setDate(isoDate);
      setDateMessage(dayjs(isoDate) < dayjs('1900-01-01') ? 'Введите корректную дату' : dayjs(isoDate) > dayjs() ? 'Введите дату в прошлом' : undefined);
    } else {
      setDateMessage(message);
    }
  }

  const onTimeChange = (isoTime?: string) => {
    const message = 'Нужно ввести время'

    if (isoTime) {
      setTime(isoTime);
      setTimeMessage(undefined);
    } else {
      setTimeMessage(message);
    }
  }

  const onPlaceChange = (place: IPlace | string | null) => {
    if (typeof place === 'object') {
      setPlace(place);
      setPlaceMessage(undefined);
    } else {
      setPlace(place => ({ ...place!, lat: 0, lon: 0 }) );
      setPlaceMessage('Выберите город из списка');
    }
  }

  const checkErrorMode = (val: any) => Boolean(val) ? 'error' : 'normal';

  const handleSubmit = async () => {
    const dataPost: MapData = {
      date: dayjs.utc(date).format('DD.MM.YYYY'),
      time: dayjs.utc(time).format('HH:mm:ss'),
      lat: place?.lat || 0,
      lon: place?.lon || 0,
      place: place?.name || '',
      name,
      email,
      reason
    }

    const dataPostWithEmail = {
      ...dataPost,
      email,
      source: encodeURIComponent(window.location.href)
    }

    setData(dataPostWithEmail || '');
    sendUnisender({ name, email }, props.setError);
    getMap(dataPost, props.setError).then(res => {
      setMapImage(res || '');
    })
    getInterpretation(dataPostWithEmail, props.setError).then(res => {
      setInterpretation(res || '');
    })

    googleEvent('form_sent_successful')
  }

  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const date = searchParams.get('date');
    const time = searchParams.get('time');
    const place = searchParams.get('place');
    const email = searchParams.get('email');
    const lat = Number(searchParams.get('lat'));
    const lon = Number(searchParams.get('lon'));

    if(date && time && place && email && lat && lon) {
      const dataPost: MapData = {
        date,
        time,
        lat,
        lon,
        place,
        name,
        email
      }
  
      const dataPostWithEmail = {
        ...dataPost,
        email,
        isFull: true, 
        source: encodeURIComponent(window.location.href)
      }

      setData(dataPostWithEmail || '');
      getMap(dataPost, props.setError).then(res => {
        setMapImage(res || '');
      })
      getInterpretation(dataPostWithEmail, props.setError).then(res => {
        setInterpretation(res || '');
      })
    }
  }, [])

  if(mapImage && interpretation) {
    return <NatalMapSunResult token={props.token} />
  }

  if(searchParams.get('email')) {
    return null;
  }

  return (
    <Container>
      <Description>
        <div className={'title'}>Постройте вашу натальную карту</div>
        <div className={'subtitle'}>Заполните форму и получите вашу карту</div>
      </Description>

      <FormContainer>
        <InputContainer label='Имя' message={nameMessage}>
          <StyledInput
            name="username"
            size='small'
            value={name}
            onChange={onNameChange}
            placeholder='Как Вас зовут?'
            mode={checkErrorMode(nameMessage)}
          />
        </InputContainer>

        <InputContainer label='Почта' message={emailMessage}>
          <StyledInput
            name="email"
            size='small'
            value={email}
            onChange={onEmailChange}
            mode={checkErrorMode(emailMessage)}
            placeholder='Ваш e-mail'
          />
        </InputContainer>

        <InputContainer label='Дата рождения' message={dateMessage}>
          <StyledDateTimeInput
            size='small'
            type='date'
            value={date}
            onChange={onDateChange}
            onInvalid={onDateChange}
            hideIcon={true}
            mode={checkErrorMode(dateMessage)}
            utcMode
          />
        </InputContainer>

        <InputContainer label='Время рождения' message={timeMessage}>
          <StyledDateTimeInput
            size='small'
            type='time'
            value={time}
            hideIcon={true}
            onChange={onTimeChange}
            onInvalid={onTimeChange}
            mode={checkErrorMode(timeMessage)}
            utcMode
          />
        </InputContainer>

        <InputContainer label='Место рождения' message={placeMessage}>
          <StyledPlaceInput
            size='small'
            value={place?.name || ''}
            onSelect={onPlaceChange}
            onChange={onPlaceChange}
            placeholder='Начните вводить место рождения'
            asyncDataFn={mainApi.places.bind(mainApi)}
            asyncDetailFn={mainApi.place.bind(mainApi)}
            mode={checkErrorMode(placeMessage)}
            lang="ru"
            rightIcon={postfix}
          />
        </InputContainer>

        {/* <InputContainer label='Для чего вы строите натальную карту?'>
          <div>
            <RadioStyled><Radio onChange={() => {setReason('person')}}  checked={reason === 'person'} />Для себя, хочу получить описание личности  </RadioStyled>
            <RadioStyled><Radio onChange={() => {setReason('astrolog')}} checked={reason === 'astrolog'}  />Я астролог, хочу построить карту для своего клиента </RadioStyled>
          </div>
        </InputContainer> */}

      </FormContainer>

      <Footer>
        <FooterContainer>
          <StyledButton
            name="submit-button"
            color="blue"
            onClick={handleSubmit}
            disabled={isDisabled}
          >
            Построить
          </StyledButton>

          <span>
            Нажимая «Построить», вы принимаете <br /><Link href={s3urls.termsOfUseRU} target='_blank' rel='noreferrer'>Пользовательское соглашение</Link> и <Link href={s3urls.privacyPolicyRU} target='_blank' rel='noreferrer'>Политику конфиденциальности</Link>
          </span>

        </FooterContainer>
        
      </Footer>
    </Container>
  )
}

const RadioStyled = styled.label`
  width: 100% !important;
  display: flex !important;
  gap: 10px;
  margin-bottom: 10px;
  font-size: initial;
  font-size: 1rem !important;

  span {
    border-color: #4093F4 !important;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100dvh;

  & img {
    max-width: 97.5%;
  }
`

const Description = styled.div`
  width: 42.5rem;
  max-width: 85%;
  margin-top: 1rem;
  text-align: left;

  .title {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.75rem;
  }

  .subtitle {
    color: var(--text-third);
  }
`

const FormContainer = styled.div`
  width: 42.5rem;
  max-width: 85%;
  font-size: 0.875rem;
  margin: 2rem 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;

  & > :nth-child(5), & > :nth-child(n + 6) {
    grid-column: 1 / 3;
  }

  @media (max-width: 768px) {
    & > :nth-child(1) {
    grid-column: 1 / 3;
    }

    & > :nth-child(2) {
      grid-column: 1 / 3;
    }
  }

  input {
    font-size: 16px !important;
  }
`

const StyledInput = styled(Input) <{ error?: boolean }>`
  ${props => props.error && css`
    input {
      border-color: var(--red-color);
    }
  `};

  @media (max-width: 768px){
    font-size: 1rem;
  }
`

const StyledDateTimeInput = styled(DateTimeInput) <{ error?: boolean }>`
  ${props => props.error && css`
    border-color: var(--red-color);
  `};

  @media (max-width: 768px){
    font-size: 1rem;
  }
`

const StyledPlaceInput = styled(PlaceInput)<{error?: boolean}>`
  /* ${props => props.error && css`
    border: 1px solid var(--red-color);
  `};   */

  div {
    top: 25%;
    left: 95%;
  }

  @media (max-width: 768px){
    font-size: 1rem;

    div {
      top: 25%;
      left: 90%;
    }
  }
`

export const StyledButton = styled(Button)`
   color: var(--white-color) !important;
   background: #4093F4 !important;
   font-size: 1.25rem;
`

const Footer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--gray-color);
  font-size: 1rem;

  background: var(--white-color);
  width: 100%;
  padding: 1rem;
  border-top: 1px solid var(--black-light-color);

  @media (max-width: 768px){
    font-size: 0.8rem;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 42.5rem;
  max-width: 85%;

  button {
    width: 100%;
  }
`

const Link = styled.a`
  color: #4093F4
`
