import styled from 'styled-components';
import { Badge, Button } from '../../../libs';

export default function SuccessInfo({ email }: { email: string }) {
  const onClickHandler = () => {
    const utmMarks = 'utm_source=chronos_plus&utm_medium=unisender&utm_content=fincap&utm_term=thank_you';
    window.open(`https://p.chronos.ru/finconsultation?${utmMarks}`, '_blank');
  };

  const letter = `https://widget.chronos.mg/${require('../assets/images/letter.png')}`;

  return (
    <Container>
      <Success>
        <Image src={letter} alt="letter" />
        <Title>Поздравляем с покупкой</Title>
        <Text>
          Мы отправили результат на почту <span>{email}</span>
        </Text>
      </Success>

      <>
        <OfferTitle>Дарим скидку на первую консультацию с астрологом</OfferTitle>

        <Offer>
          <div className="description">
            <div className="left">
              <div className="title">Консультация</div>
              <div className="subtitle">Скидка действует 30 дней с даты покупки</div>
            </div>
            <div className="right">
              <StyledBadge value="-30%" />
              <div>
                <div className="price">14 000₽</div>
                <div className="old_price">20 000₽</div>
              </div>
            </div>
          </div>

          <StyledButton onClick={onClickHandler}>Забронировать консультацию</StyledButton>
        </Offer>
      </>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  color: var(--text-color);
  width: 100%;
`;

const Success = styled.div`
  ul {
    margin-top: 0.125rem;
    padding: 0 0 0 1rem;
  }
`;

const Image = styled.img`
  width: 120px;
  height: 100px;
`;

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 0.5rem;
`;

const Text = styled.div`
  font-size: 0.875rem;
  font-weight: 500;

  span {
    font-weight: 700;
  }

  @media (max-width: 768px) {
    margin-left: 1.5rem;
    margin-right: 2rem;
  }
`;

const OfferTitle = styled.div`
  font-size: 1rem;
  font-weight: 500;
  margin-top: 1.5rem;
`;

const Offer = styled.div`
  padding: 1.5rem;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.04);
  max-width: 35rem;
  margin-top: 1rem;

  .description {
    display: flex;
    justify-content: space-between;
  }

  .left {
    text-align: left;
    width: 60%;
  }

  .right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    text-align: right;
  }

  .title {
    font-size: 1.25rem;
    font-weight: 500;
  }

  .subtitle {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--text-secondary);
    margin-top: 0.25rem;
  }

  .price {
    font-size: 1.25rem;
    font-weight: 500;
  }

  .old_price {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--text-secondary);
    text-decoration: line-through;
    margin-top: 0.25rem;
  }
`;

const StyledBadge = styled(Badge)`
  background: #4093f4;
  border-radius: 1rem;
  color: white !important;
  padding: 0.5rem 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
`;

const StyledButton = styled(Button)`
  color: white !important;
  background: #4093f4 !important;
  font-size: 1.125rem;
  width: 100%;
  margin-top: 1rem;
  box-sizing: border-box;
`;
