import React from 'react';
import styled, { css } from 'styled-components';
import { WIDGET_TYPE } from '../../App';
import { OkMainIcon, OkPopularIcon } from '../../assets/icons';
import { PlanDescription, PlanDescriptionLablife } from '.././PlanDescription';

export interface IPlan {
  id: string;
  title: string;
  price: number;
  priceOld: number;
  popular: boolean;
  type?: WIDGET_TYPE;
}

export default function PlanStaged({
  plan,
  isSelected,
  onSelect,
  type,
  symbol,
}: {
  plan: IPlan;
  isSelected: boolean;
  onSelect(plan: IPlan): void;
  type: WIDGET_TYPE;
  symbol: string;
}) {
  const { id, title, price, priceOld, popular } = plan;
  const percentOfDiscount = Math.abs(Math.round(((priceOld - price) / priceOld) * 100));

  return (
    <Container selected={isSelected} popular={popular}>
      {popular && <Popular>Популярное</Popular>}
      <Title>{title}</Title>
      <div>{type === 'lablife' ? <PlanDescriptionLablife id={id} /> : <PlanDescription id={id} />}</div>
      <Discount>
        <div>-{percentOfDiscount}%</div>
      </Discount>
      <Price>
        <NewPrice>
          {price}
          {symbol}
        </NewPrice>
        <OldPrice>
          {priceOld}
          {symbol}
        </OldPrice>
      </Price>
      <Button selected={isSelected} popular={popular} onClick={() => onSelect(plan)}>
        {isSelected && popular ? (
          <>
            <OkPopularIcon /> Выбрано
          </>
        ) : isSelected && !popular ? (
          <>
            <OkMainIcon /> Выбрано
          </>
        ) : (
          'Выбрать'
        )}
      </Button>
    </Container>
  );
}

const Popular = styled.div`
  width: 5.5rem !important;
  display: block;
  position: absolute;
  right: -0.7rem;
  top: -1.15rem;
  background: var(--orange-color);
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 140%;
  padding: 0.1874rem 0.5625rem 0.1875rem 0.5625rem;
  border-radius: 1.625rem;
`;

const Container = styled.div<{ selected: boolean; popular: boolean }>`
  display: flex !important;
  background-color: var(--white-color);
  padding: 0.81rem 1.01rem;
  position: relative;
  height: 19.25rem;
  width: 18.7rem;
  min-width: 7rem;
  min-height: 16.875rem;
  border: 1px solid var(--black-light-color);
  box-sizing: border-box;
  border-radius: 0.4375rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;

  & > div {
    width: 100%;
  }

  @media (max-width: 768px) {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 100% !important;
    margin-bottom: 1rem;
  }

  @media (max-width: 1055px) {
    width: 11rem;
  }

  @media (max-width: 1537px) {
    width: 20rem;
  }

  ${(props) =>
    props.selected &&
    css`
      padding: 0.75rem 0.95rem;
      height: 21rem;
      width: 18.7rem;
    `}

  ${(props) =>
    props.popular &&
    css`
      background-color: var(--orange-light-color);
    `}

  ${(props) =>
    props.selected &&
    !props.popular &&
    css`
      border: 2px solid var(--main-color);
    `}

  ${(props) =>
    props.selected &&
    props.popular &&
    css`
      border: 2px solid var(--orange-color);
    `}

  :nth-child(2) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 0.75rem;
  position: relative;
  text-align: left;
  height: 1rem;
  color: var(--tariff-name-color);
`;
const Discount = styled.div`
  width: 100%;
  font-size: 0.875rem;
  color: var(--main-color);

  & > div {
    line-height: 140%;
    font-weight: 600;
    background: var(--main-color);
    color: white;
    border-radius: 3.125rem;
    padding: 0.1875rem 0.5625rem 0.125rem 0.5625rem;
  }
`;

const Price = styled.div`
  width: 100%;
  height: 2rem;
  margin-top: 0.65rem !important;
  margin-bottom: 0;
`;
const NewPrice = styled.div`
  font-size: 2rem;
  margin-right: 0.55rem;
`;

const OldPrice = styled.div`
  margin-top: 1.1rem !important;
  font-size: 1rem;
  color: var(--tariff-name-color);
  text-decoration: line-through;
`;

const Button = styled.button<{ selected: boolean; popular: boolean }>`
  vertical-align: bottom;
  margin-top: 1.3rem !important;
  width: 100%;
  height: 1.8rem;
  min-height: 1.8rem;
  font-size: 0.875rem;
  color: var(--main-color);
  border: 1px solid var(--main-color) !important;
  background-color: var(--white-color);
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.6rem;
  }

  ${(props) =>
    props.popular &&
    !props.selected &&
    css`
      border: 1px solid var(--orange-color) !important;
      background-color: var(--orange-light-color);
      color: var(--orange-color);
    `}

  ${(props) =>
    props.popular &&
    props.selected &&
    css`
      border: 1px solid var(--orange-color) !important;
      background-color: var(--orange-color);
      color: var(--white-color);
    `}

  ${(props) =>
    !props.popular &&
    props.selected &&
    css`
      background-color: var(--main-color);
      color: var(--white-color);
    `}

  ${(props) =>
    props.selected &&
    props.popular &&
    css`
      border: 2px solid var(--orange-color) !important;
    `}
`;
