import React from 'react';
import styled from 'styled-components';

export default function AppEmptyPage({ children }: { children: React.ReactNode }) {
  return <EmptyPage>{children}</EmptyPage>;
}

const EmptyPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: var(--background);
`;
