import { WIDGET_TYPE } from '../../App';
import { IPlan } from '../../components/Plan';
import { Prices } from '../../store/usePricesStore';

export const getPlans = (prices: Prices) => {
  let basePrice: number = prices.oldDefault;
  let financePrice: number = prices.oldFinance;
  let relationsPrice: number = prices.oldRelations;

  const plans: {
    [key in WIDGET_TYPE]: IPlan[];
  } = {
    default: [
      {
        id: 'default',
        title: 'Базовый план',
        price: 0,
        priceOld: basePrice,
        popular: false,
      },
      {
        id: 'finance',
        title: 'Финансы',
        price: 0,
        priceOld: financePrice,
        popular: false,
      },
      {
        id: 'relations',
        title: 'Финансы и отношения',
        price: 0,
        priceOld: relationsPrice,
        popular: true,
      },
    ],

    lablife: [
      {
        id: 'birth',
        title: '4 рождения',
        price: 0,
        priceOld: prices.lablifeOldBirthPrice,
        popular: false,
        type: 'lablife',
      },
      {
        id: 'default',
        title: 'Базовый план',
        price: 0,
        priceOld: prices.lablifeDefaultPrice,
        popular: false,
        type: 'lablife',
      },
    ],
    partial: [],
  };

  return plans;
};
