import { getUTMCommentForCP } from '../../../helpers/cloudPayments';
import {
  Error,
  errorDefault,
  errorInvalidEmail,
  errorPromoIsExpired,
  errorPromoIsNotFound,
  errorPromoIsUsed,
} from './errors';
import { PaymentData } from './types';

const apiUrl = (process.env.REACT_APP_BACKEND as string) + '/api';

export async function getPaymentResult(data: PaymentData, setError: (value: Error) => void) {
  try {
    const dataPost = { ...data };

    const operation = await hash(JSON.stringify(dataPost));
    dataPost.operation = operation;
    dataPost.comment = getUTMCommentForCP();

    let url = `${apiUrl}/widget/${dataPost.token}/payment/config`;

    if (dataPost.partner) url += `?partner=${dataPost.partner}`;

    const result = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dataPost),
      credentials: 'include',
    }).then((response) => response.json());

    if (result.failed && result.data.length > 0) {
      const error = result.data[0].trim();
      switch (error) {
        case 'INVALID_EMAIL':
          setError(errorInvalidEmail);
          return;
        case 'PROMO_ALREADY_USED':
          setError(errorPromoIsUsed);
          return;
        case 'PROMO_EXPIRED':
          setError(errorPromoIsExpired);
          return;
        case 'PROMO_NOT_FOUND':
          setError(errorPromoIsNotFound);
          return;
      }
      throw 'Config error';
    } else {
      return result;
    }
  } catch (err) {
    setError(errorDefault);
  }
}

export async function hash(message: string) {
  const uint8 = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', uint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

export async function getPromoDiscount(token: string, promo: string, email: string) {
  let url = `${apiUrl}/widget/${token}/check_promo`;
  const result = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      promo,
      email,
    }),
    credentials: 'include',
  }).then((response) => response.json());

  if (result.failed) {
    console.log('Ошибка промокода', result);
    return 0;
  } else {
    return result.data.discount;
  }
}
