import { ChronosApi, IPlace, S3Urls } from '../libs';
import { TGender } from '../components/GenderSelector';

export const mainApi = new ChronosApi(
  process.env.REACT_APP_BACKEND as string,
  '',
  undefined,
  `https://${process.env.REACT_APP_NODE_ENV === 'development' ? 'dev-' : ''}places.chronos.mg` as string,
);
export const s3urls = new S3Urls();

export interface IStartConfigPrices {
  default: number;
  birth: number;
  relations: number;
  finance: number;
  autoprediction: number;
  finance_capacity: number;
  lablifeDefaultPrice: number;
  lablifeOldBirthPrice: number;
  minimumPrice: number;
  oldDefault: number;
  oldFinance: number;
  oldRelations: number;
}

export interface IPaymentDataBase {
  type?: string;
  cloudPayments?: any;
  email: string;
  gender: TGender;
  promo?: any;
  subtype?: string;
  createdAt?: number;
  relations: boolean;
  partner?: string;
  finance: boolean;
  birth: boolean;
  operation?: any;
  token?: string;
  autoprediction: boolean;
  apDate?: string;
  apTime?: string;
  country: string;
  K8S_NAMESPACE?: string;
  comment?: string;
}

export interface IPaymentData extends IPaymentDataBase {
  name: string;
  date: string;
  time: string;
  place: string;
  lat: number;
  lon: number;
  backUrl?: string;
  failUrl?: string;
  successUrl?: string;
  // captchaToken: string | null | undefined;
}

export interface IStagedPaymentData extends IPaymentDataBase {
  lablife: boolean;
  isStaged?: boolean;
  backUrl?: string;
  failUrl?: string;
  successUrl?: string;
  // captchaToken: string | null | undefined;
}

export const apiUrl = `${process.env.REACT_APP_BACKEND}/api`;

export const getConfigPrices = (token: string, partner: string, country: string): Promise<IStartConfigPrices> => {
  let url = `${apiUrl}/widget/${token}/config?country=${country}`;

  if (partner && partner !== 'rambler') {
    url += `&partner=${partner}`;
  }

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).then((data) => data.json());
};

export async function getPaymentResult(dataPost: IPaymentData, token: string, partner: string) {
  let url = `${apiUrl}/widget/${token}/payment/config`;
  if (partner) url += `?partner=${partner}`;
  const result = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(dataPost),
    credentials: 'include',
  }).then((response) => response.json());

  return result;
}

export async function getStagedPaymentResult(dataPost: IStagedPaymentData, token: string, partner: string) {
  let url = `${apiUrl}/widget/${token}/payment/config`;
  if (partner) url += `?partner=${partner}`;
  const result = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(dataPost),
    credentials: 'include',
  }).then((response) => response.json());

  return result;
}

export async function hash(message: string) {
  const uint8 = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', uint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}
