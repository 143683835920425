export interface Error {
  title: string;
  content: string;
}

export const errorPromoIsUsed = {
  title: 'Промокод уже был использован',
  content: 'Посмотрите письмо от chronos.mg в вашей почте',
};

export const errorPromoIsExpired = {
  title: 'Срок действия промокода истек',
  content: 'Вы указали неверный промокод',
};

export const errorPromoIsNotFound = {
  title: 'Промокод не найден',
  content: 'Вы указали неверный промокод',
};

export const errorInvalidEmail = {
  title: 'Некорректная почта',
  content: 'Вы указали некорректную почту. Обратитесь в поддержку',
};

export const errorDefault = {
  title: 'Ошибка',
  content: 'Произошла ошибка. Обратитесь в поддержку',
};
