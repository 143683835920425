import { Error, INVALID_EMAIL, errorDefault, errorFinanceWasGot, errorInvalidEmail } from './errors';
import { PaymentData } from './types';

const apiUrl = `https://api.chronos.mg/api`;

export async function getPaymentResult(data: PaymentData, setError: (value: Error) => void) {
  try {
    const dataPost = { ...data };

    const operation = await hash(JSON.stringify(dataPost));
    dataPost.operation = operation;

    let url = `${apiUrl}/widget/${dataPost.token}/payment/config`;

    if (dataPost.partner) url += `?partner=${dataPost.partner}`;

    const result = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dataPost),
      credentials: 'include',
    }).then((response) => response.json());

    if (result.failed || !result.success) {
      if (result.data[0] === INVALID_EMAIL) {
        setError(errorInvalidEmail);
      } else {
        setError(errorDefault);
      }
    } else {
      return operation;
    }
  } catch (err) {
    setError(errorFinanceWasGot);
  }
}

export async function hash(message: string) {
  const uint8 = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', uint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}
