const apiUrl = (process.env.REACT_APP_BACKEND as string) + '/api';

interface FinanceCapacityData {
  name: string;
  date: string;
  time: string;
  place: string;
  lat: number;
  lon: number;
  email: string;
}

export async function getTelegramLink(data: FinanceCapacityData) {
  let url = `${apiUrl}/natal-calc-telegram`;
  
  const result = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
    credentials: 'include'
  }).then((response) => response.json());

  if (!result?.data?.telegramLink) {
    alert('Ошибка при обращении к серверу')
    console.log('Ошибка ', result)
    return 0;
  } else {
    return result.data.telegramLink;
  }

}

// var formData = new FormData();
//   for ( let key in data ) {
//     //@ts-ignore
//     formData.append(key, data[key]);
//   }