import { CurrencyCode, Region } from '../../../libs';

export interface PaymentMethod {
  id: Region;
  country: Region;
  title: string;
  code: CurrencyCode;
  symbol: string;
}

export const paymentMethods: PaymentMethod[] = [
  {
    id: Region.ru,
    country: Region.ru,
    title: 'Карта РФ',
    code: CurrencyCode.RUB,
    symbol: '₽',
  },
  {
    id: Region.kz,
    country: Region.kz,
    title: 'Казахстанская карта',
    code: CurrencyCode.KZT,
    symbol: '₸',
  },
  {
    id: Region.us,
    country: Region.us,
    title: 'Иностранная карта',
    code: CurrencyCode.USD,
    symbol: '$',
  },
];
