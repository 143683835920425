import { useEffect } from 'react';
import { YMInitializer } from 'react-yandex-metrika';
import styled, { css } from 'styled-components';
import FinanceApp from './apps/finance';
import FinanceCapacityApp from './apps/finance-capacity';
import NatalMapApp from './apps/natal-map';
import NatalMapSunApp from './apps/natal-map-sun';
import { Idp } from './apps/idp';
import { usePricesStore } from './store/usePricesStore';
import mainCss from './style/css/main.css?raw';
import { widgetUICss } from './libs';


export const ymID = 49958389;

export type WIDGET_TYPE = 'default' | 'lablife' | 'partial';
const financeToken = 'partial_idp_earnings';
const financeCapacityToken = 'finance_capacity';
const natalMapToken = 'natal_map';
export const natalMapSunToken = 'natal_map_sun';
export const natalMapSunTokenFull = 'natal_map_sun_full';

const defaultCountry = 'ru'

export default function App({
  token,
  type = 'default',
  sidebar = 'default',
  partner = '',
  staged
}: {
  token: string;
  type?: WIDGET_TYPE;
  sidebar?: string;
  partner?: string;
  staged?: boolean;
}) {
	const setPrices = usePricesStore().setPrices

	useEffect(() => {
		setPrices(token, partner, defaultCountry)
	}, [])

  const searchParams = new URLSearchParams(window.location.search);
  const partnerId = partner || searchParams.get('partner');

  let partnerParams: any = {};
  // если пользователь не авторизован (визит)
  partnerParams = {
    from_partner: partnerId || false
  }

  const ymOptions = {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    userParams: {
      beta: false,
      ...partnerParams
    }
  }

  let WidgetComponent;
  switch(token) {
    case natalMapToken:
      WidgetComponent = <NatalMapApp token={token} partner={partner} />
      break;
    case natalMapSunToken:
      WidgetComponent = <NatalMapSunApp token={token} partner={partner} />
      break;
    case natalMapSunTokenFull:
      WidgetComponent = <NatalMapSunApp token={token} partner={partner} />
      break;
    case financeToken:
      WidgetComponent = <FinanceApp token={token} partner={partner} />
      break;
    case financeCapacityToken:
      WidgetComponent = <FinanceCapacityApp token={token} partner={partner} />
      break;
    default:
      WidgetComponent = <Idp token={token} partner={partner} sidebar={sidebar} staged={staged} type={type} />;
  }

  return (
    <>
      <YMInitializer
  			accounts={[ymID]}
  			options={ymOptions}
  		/>

      <AppContainer sidebar={sidebar} className='app'>
        {WidgetComponent}
      </AppContainer>
    </>
  )
}

const AppContainer = styled.div<{sidebar: string}>`

  ${mainCss.replace(/body\s+{/g, '').replace(/}/g, '')}
  ${widgetUICss.replace(/html\s+{/g, '').replace(/}/g, '')}

  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-family: "Inter", "Apercu Pro", sans-serif;
  font-size: 16px;

  background-color: #fafafa;

  ${(props) =>
    props.sidebar !== 'default' &&
    css`
      flex-direction: column;
    `}

  @media (max-width: 1320px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

`;
