import { TOKENS } from '../../../App';
import { Error, errorDefault } from './errors';
import { MapData } from './types';

export const apiUrl = `${process.env.REACT_APP_BACKEND}/api`;

export async function getMap(data: MapData, setError: (value: Error) => void) {
  try {
    const params = Object.entries(data).reduce((acc, next) => `${acc}${next[0]}=${next[1]}&`, '');

    let url = `${apiUrl}/circle?${params}`;

    const result = await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'image/png' },
      credentials: 'include',
    });

    const blob = await result.blob();
    return URL.createObjectURL(blob);
  } catch (err) {
    console.log(err);
    setError(errorDefault);
  }
}

export async function addStatLog(data: MapData, partner: string) {
  try {
    let url = `${apiUrl}/widget/log/add`;

    const result = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(getDataForStatLog(data, partner)),
      credentials: 'include',
    });

    console.log(await result.json());
  } catch (err) {
    console.log(err);
  }
}

function getDataForStatLog(data: MapData, partner: string) {
  return {
    data,
    partner,
    email: data.email,
    widgetName: TOKENS.NATAL_MAP,
    origin: window.location.href,
    subUnisender: false,
  };
}
