import { Button } from '../../libs';
import React from 'react';
import styled from 'styled-components';
import { HeartIcon, LogoIcon } from '../../assets/icons';

export default function SuccessInfoStaged({
  email,
  devPlanHref,
  isAutoprediction,
  isStaged,
}: {
  email: string;
  devPlanHref: string;
  isAutoprediction: boolean;
  isStaged?: boolean;
}) {
  return (
    <Container>
      <Header>
        <Logo>
          <div className={'icon'}>
            <LogoIcon />
          </div>{' '}
          <span>Chronos</span>
        </Logo>
      </Header>

      <Content>
        <div className={'icon'}>
          <HeartIcon />
        </div>

        <Title>Спасибо за покупку!</Title>

        <Email>
          Мы отправили доступ к Плану развития на почту: <br />
          <span>{email}</span>
        </Email>

        <Subtitle>Не получили письмо?</Subtitle>
        <List>
          <li>
            <span>Убедитесь, что указали правильный почтовый адрес</span>
          </li>
          <li>
            <span>Проверьте папку "Спам"</span>
          </li>
          <li>
            <span>Напишите </span>
            <a target="_blank" href="mailto:support@chronos.mg" rel="noreferrer">
              в поддержку
            </a>
          </li>
        </List>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  padding: 2rem;

  a {
    text-decoration: none;
    color: var(--main-color);
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  margin-bottom: 5rem;
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.5rem;
  font-weight: 500;

  .icon {
    width: 2.25rem;
    height: 2.25rem;
    margin-right: 0.5rem;
  }
`;

const CustomButton = styled(Button)`
  color: var(--main-color);
  font-size: 0.875rem;
  font-weight: 500;
  border: 2px solid var(--main-color);
  border-radius: 3.75rem;
  padding: 0.5rem 1rem;
`;

const Content = styled.div`
  text-align: left;

  .icon {
    width: 3rem;
    height: 3rem;
  }
`;

const Title = styled.div`
  color: var(--main-color);
  font-size: 3rem;
  font-weight: 500;
  margin: 1rem 0;
`;

const Subtitle = styled.div`
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

const Email = styled.div`
  font-size: 1.25;
  margin-bottom: 2rem;
  line-height: 2rem;

  span {
    font-weight: 500;
    background: rgba(6, 6, 6, 0.03);
    border-radius: 0.75rem;
    padding: 0.5rem 1rem;
  }
`;

const List = styled.ul`
  color: var(--main-color);
  margin: 0;
  padding-left: 1rem;

  li {
    margin-bottom: 1rem;
  }

  span {
    color: var(--text-primary);
  }
`;
