import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { devtools } from 'zustand/middleware';
import { persist } from 'zustand/middleware';
import { MapData, SunInterpretation } from './types';

export interface NatalSunMapState {
  mapImage?: string;
  setMapImage(mapImage: string | undefined): void;
  interpretation?: SunInterpretation;
  setInterpretation(interpretation: SunInterpretation | undefined): void;
  data?: MapData;
  setData(data: MapData | undefined): void;
}

export const useNatalSunMapStore = create<NatalSunMapState>()(
  devtools(
    immer((set) => ({
      setMapImage: async (mapImage: string) => {
        set({
          mapImage,
        });
      },
      setInterpretation: async (interpretation: any) => {
        set({
          interpretation,
        });
      },
      setData: async (data: MapData) => {
        set({
          data,
        });
      },
    })),
  ),
);
