import React from 'react';
import styled from 'styled-components';
import { WIDGET_TYPE } from '../App';
import Plan, { IPlan } from './Plan';
import PlanStaged from './stagedPayments/PlanStaged';

export default function PlanSelector({
  plans,
  selectedPlan,
  onSelect,
  type,
  staged,
  symbol,
}: {
  plans?: IPlan[];
  selectedPlan?: IPlan | null;
  type: WIDGET_TYPE;
  staged?: boolean;
  onSelect(plan: IPlan): void;
  symbol: string;
}) {
  const getPlanComponent = (plan: IPlan, index: number) => {
    return staged ? (
      <PlanStaged
        key={`plan_${index}`}
        isSelected={selectedPlan ? plan.id === selectedPlan.id : false}
        onSelect={onSelect}
        plan={plan}
        type={type}
        symbol={symbol}
      />
    ) : (
      <Plan
        key={`plan_${index}`}
        isSelected={selectedPlan ? plan.id === selectedPlan.id : false}
        onSelect={onSelect}
        plan={plan}
        type={type}
        symbol={symbol}
      />
    );
  };

  return (
    <SelectorWrapper>
      <PlanWrapper>
        {plans?.map((plan, i) => {
          return getPlanComponent(plan, i);
        })}
      </PlanWrapper>
    </SelectorWrapper>
  );
}

const SelectorWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0.4rem;
  margin-bottom: 1rem;

  div {
    display: flex;
    align-items: center;
    align-self: center;
  }

  @media (max-width: 768px) {
    width: 100vw;
  }

  @media (max-width: 375px) {
    flex-direction: row;
  }
`;

const PlanWrapper = styled.div`
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-content: start;
  justify-content: center;
  grid-gap: 2rem;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 1280px) {
    display: flex;
  }

  @media (max-width: 1055px) {
    flex-wrap: wrap;
  }
`;
