import { IPlace, Button, DateTimeInput, Input, InputContainer, PlaceInput } from '../../../libs';
import dayjs from 'dayjs';
import * as EmailValidator from 'email-validator';
import { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { mainApi, s3urls } from '../../../helpers/api';
import { LocationIcon } from '../assets/icons/system';
import { Error } from '../helpers/errors';
import { addStatLog, getMap } from '../helpers/api';
import { MapData } from '../helpers/types';

interface FormProps {
  token: string;
  partner: string;
  setError(value: Error): void;
}

export default function Form(props: FormProps) {
  const defaultDate = dayjs().toISOString();
  const defaultTime = dayjs().utc().set('hour', 12).set('minute', 0).set('second', 0).toISOString();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState<string>(defaultDate);
  const [time, setTime] = useState<string>(defaultTime);
  const [place, setPlace] = useState<IPlace | null>(null);

  const [nameMessage, setNameMessage] = useState<string | undefined>();
  const [emailMessage, setEmailMessage] = useState<string | undefined>();
  const [timeMessage, setTimeMessage] = useState<string | undefined>();
  const [dateMessage, setDateMessage] = useState<string | undefined>();
  const [placeMessage, setPlaceMessage] = useState<string | undefined>();

  const [mapUrl, setMapUrl] = useState<string>();

  const postfix = <LocationIcon />;

  const isDisabled = useMemo((): boolean => {
    return Boolean(
      nameMessage ||
        !name ||
        emailMessage ||
        !email ||
        timeMessage ||
        !time ||
        dateMessage ||
        !date ||
        placeMessage ||
        !place,
    );
  }, [nameMessage, name, emailMessage, email, timeMessage, time, dateMessage, date, placeMessage, place]);

  const onNameChange = (name: string) => {
    const resultName = name.trim();
    setName(name);
    if (/^[a-z,а-я,ё,\s]*$/i.test(resultName)) {
      setNameMessage(resultName.length === 0 ? 'Имя не может быть пустым' : undefined);
    } else {
      setNameMessage('В имени могут быть только буквы');
    }
  };

  const onEmailChange = (email: string) => {
    setEmail(email);
    setEmailMessage(EmailValidator.validate(email) || email === '' ? undefined : 'Введите корректный email');
  };

  const onDateChange = (isoDate?: string) => {
    const message = 'Нужно ввести дату';
    if (isoDate) {
      setDate(isoDate);
      setDateMessage(
        dayjs(isoDate) < dayjs('1900-01-01')
          ? 'Введите корректную дату'
          : dayjs(isoDate) > dayjs()
            ? 'Введите дату в прошлом'
            : undefined,
      );
    } else {
      setDateMessage(message);
    }
  };

  const onTimeChange = (isoTime?: string) => {
    const message = 'Нужно ввести время';

    if (isoTime) {
      setTime(isoTime);
      setTimeMessage(undefined);
    } else {
      setTimeMessage(message);
    }
  };

  const onPlaceChange = (place: IPlace | string | null) => {
    if (typeof place === 'object') {
      setPlace(place);
      setPlaceMessage(undefined);
    } else {
      setPlace((place) => ({ ...place!, lat: 0, lon: 0 }));
      setPlaceMessage('Выберите город из списка');
    }
  };

  const checkErrorMode = (val: any) => (Boolean(val) ? 'error' : 'normal');

  const handleSubmit = async () => {
    const dataPost: MapData = {
      date: dayjs.utc(date).format('DD.MM.YYYY'),
      time: dayjs.utc(time).format('HH:mm:ss'),
      lat: place?.lat || 0,
      lon: place?.lon || 0,
      place: place?.name || '',
      name,
      email,
    };

    getMap(dataPost, props.setError).then((res) => {
      if (props.partner) {
        addStatLog(dataPost, props.partner);
      }

      setMapUrl(res);
    });
  };

  if (mapUrl) {
    return (
      <Container>
        <img src={mapUrl} alt="natal map" />
      </Container>
    );
  }

  return (
    <Container>
      <Description>
        <div className={'title'}>Постройте вашу натальную карту</div>
        <div className={'subtitle'}>Заполните форму и получите вашу карту</div>
      </Description>

      <FormContainer>
        <InputContainer label="Имя" message={nameMessage}>
          <StyledInput
            name="username"
            size="small"
            value={name}
            onChange={onNameChange}
            placeholder="Как Вас зовут?"
            mode={checkErrorMode(nameMessage)}
          />
        </InputContainer>

        <InputContainer label="Почта" message={emailMessage}>
          <StyledInput
            name="email"
            size="small"
            value={email}
            onChange={onEmailChange}
            mode={checkErrorMode(emailMessage)}
            placeholder="Ваш e-mail"
          />
        </InputContainer>

        <InputContainer label="Дата рождения" message={dateMessage}>
          <StyledDateTimeInput
            size="small"
            type="date"
            value={date}
            onChange={onDateChange}
            onInvalid={onDateChange}
            hideIcon={true}
            mode={checkErrorMode(dateMessage)}
            utcMode
          />
        </InputContainer>

        <InputContainer label="Время рождения" message={timeMessage}>
          <StyledDateTimeInput
            size="small"
            type="time"
            value={time}
            hideIcon={true}
            onChange={onTimeChange}
            onInvalid={onTimeChange}
            mode={checkErrorMode(timeMessage)}
            utcMode
          />
        </InputContainer>

        <InputContainer label="Место рождения" message={placeMessage}>
          <StyledPlaceInput
            size="small"
            value={place?.name || ''}
            onSelect={onPlaceChange}
            onChange={onPlaceChange}
            placeholder="Начните вводить место рождения"
            asyncDataFn={mainApi.places.bind(mainApi)}
            asyncDetailFn={mainApi.place.bind(mainApi)}
            mode={checkErrorMode(placeMessage)}
            lang="ru"
            rightIcon={postfix}
          />
        </InputContainer>

        <StyledButton name="submit-button" color="blue" onClick={handleSubmit} disabled={isDisabled}>
          Построить
        </StyledButton>
      </FormContainer>

      <Footer>
        Нажимая «Построить», вы принимаете <br />
        <a href={s3urls.termsOfUseRU} target="_blank" rel="noreferrer">
          Пользовательское соглашение
        </a>
      </Footer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;

  & img {
    max-width: 97.5%;
  }
`;

const Description = styled.div`
  width: 42.5rem;
  max-width: 85%;
  margin-top: 1rem;
  text-align: left;

  .title {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.75rem;
  }

  .subtitle {
    color: var(--text-third);
  }
`;

const FormContainer = styled.div`
  width: 42.5rem;
  max-width: 85%;
  font-size: 0.875rem;
  margin: 2rem 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;

  & > :nth-child(5),
  & > :nth-child(n + 6) {
    grid-column: 1 / 3;
  }

  @media (max-width: 768px) {
    & > :nth-child(1) {
      grid-column: 1 / 3;
    }

    & > :nth-child(2) {
      grid-column: 1 / 3;
    }
  }
`;

const StyledInput = styled(Input)<{ error?: boolean }>`
  ${(props) =>
    props.error &&
    css`
      input {
        border-color: var(--red-color);
      }
    `};

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const StyledDateTimeInput = styled(DateTimeInput)<{ error?: boolean }>`
  ${(props) =>
    props.error &&
    css`
      border-color: var(--red-color);
    `};

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const StyledPlaceInput = styled(PlaceInput)<{ error?: boolean }>`
  div {
    top: 25%;
    left: 95%;
  }

  @media (max-width: 768px) {
    font-size: 1rem;

    div {
      top: 25%;
      left: 90%;
    }
  }
`;

const StyledButton = styled(Button)`
  color: white !important;
  background: #4093f4 !important;
  font-size: 1.25rem;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--gray-color);
  font-size: 1rem;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;
