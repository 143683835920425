import { Loader } from '../../libs';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { WIDGET_TYPE } from '../../App';
import Form, { IData, IDataForPayment } from '../../components/Form';
import { IPlan } from '../../components/Plan';
import PlanSelector from '../../components/PlanSelector';
import Sidebar from '../../components/Sidebar';
import SuccessInfo from '../../components/SuccessInfo';
import { Title } from '../../components/Title';
import FormPayment from '../../components/stagedPayments/FormPayment';
import SuccessInfoStaged from '../../components/stagedPayments/SuccessInfoStaged';
import { getPlans } from '../../helpers/data/plans';
import { googleEvent, yandexEvent } from '../../helpers/metrics';
import { PaymentMethod, paymentMethods } from '../../helpers/payments';
import submit, { submitPartial } from '../../helpers/submit';
import submitStaged from '../../helpers/submitStaged';
import { getWidgetInstance } from '../../helpers/utils';
import { Prices, usePricesStore } from '../../store/usePricesStore';
import '../../i18n/i18n';

// import ReRecaptcha, { TReReCaptchaApi } from "../../components/ReRecaptcha";

interface IdpProps {
  token: string;
  type?: WIDGET_TYPE;
  sidebar?: string;
  partner?: string;
  staged?: boolean;
}

export const Idp = (props: IdpProps) => {
  const { token, type = 'default', sidebar = 'default', partner = '', staged } = props;

  const [payedStatus, setPayedStatus] = useState<'success' | 'failed'>();
  const [email, setEmail] = useState<null | string>(null);
  const [devPlanHref, setDevPlanHref] = useState<null | string>(null);
  const [autoprediction, setAutoprediction] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<IPlan>();
  const [plansWithPrices, setPlansWithPrices] = useState<IPlan[]>();
  const [promoError, setPromoError] = useState<string | null>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(paymentMethods[0]);

  const prices = usePricesStore().prices;
  const setPrices = usePricesStore().setPrices;

  const minimumPrice = (plansWithPrices?.find((item) => item.id === 'default')?.price || prices?.minimumPrice) ?? 1459;

  useEffect(() => {
    setPrices(token, partner, paymentMethod.country);
  }, [paymentMethod, token, partner, setPrices]);

  useEffect(() => {
    if (!prices) return;

    const plansWithPrices = getPlans(prices)[type].map((item) => ({
      ...item,
      price: prices[item.id as keyof Prices],
    }));

    const initPlan = plansWithPrices[plansWithPrices.length - 1];
    if (!initPlan) return;

    setPlansWithPrices(plansWithPrices);
    setSelectedPlan(initPlan);
  }, [prices, token, type]);

  useEffect(() => {
    googleEvent('widget_open_page');
  }, []);

  if (!prices) return null;

  async function OnSubmitStaged(formData: IDataForPayment) {
    if (getWidgetInstance() === 'ipr2023') yandexEvent('integr-widget_click-postroit', 'reachGoal', '90908943');
    if (formData.autoprediction) googleEvent('widget_added_prognosis');

    const submitF = type === 'partial' ? submitPartial : submitStaged;

    await submitF(
      // @ts-ignore
      { ...formData },
      selectedPlan!,
      setPayedStatus,
      setDevPlanHref,
      token,
      partner,
      setPromoError,
      setShowLoader,
    );
    setEmail(formData.email);
    setAutoprediction(formData.autoprediction);
  }

  async function onSubmit(formData: IData) {
    if (getWidgetInstance() === 'ipr2023') yandexEvent('integr-widget_click-postroit', 'reachGoal', '90908943');

    const submitF = type === 'partial' ? submitPartial : submit;

    await submitF(
      { ...formData },
      selectedPlan!,
      setPayedStatus,
      setDevPlanHref,
      token,
      partner,
      setPromoError,
      setShowLoader,
    );
    setEmail(formData.email);
    setAutoprediction(formData.autoprediction);
  }

  const autoPredictionPrice = {
    newPrice: prices.autoprediction,
    oldPrice: Math.round(prices.autoprediction / 0.8),
  };

  const idpContent = (
    <>
      {payedStatus !== 'success' ? (
        <Content sidebar={sidebar}>
          {type !== 'partial' && (
            <div>
              <Title step={1} text={'Выберите темы для разбора'} />

              <PlanSelector
                plans={plansWithPrices}
                selectedPlan={selectedPlan}
                onSelect={setSelectedPlan}
                type={type}
                symbol={paymentMethod.symbol}
              />

              <Title step={2} text={'Заполните форму'} />
            </div>
          )}

          <Form
            onSubmit={onSubmit}
            autoPredictionPrice={autoPredictionPrice}
            selectedPlan={selectedPlan}
            type={type}
            promoError={promoError}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          />
        </Content>
      ) : (
        <Content payedStatus={payedStatus}>
          <SuccessInfo email={email!} devPlanHref={devPlanHref!} isAutoprediction={autoprediction} />
        </Content>
      )}
    </>
  );

  const idpStageContent = (
    <>
      {payedStatus !== 'success' ? (
        <Content sidebar={sidebar}>
          <div>
            <Title step={1} text={'Выбор темы'} />

            {type !== 'partial' && (
              <PlanSelector
                plans={plansWithPrices!}
                selectedPlan={selectedPlan}
                onSelect={setSelectedPlan}
                type={type}
                staged={staged}
                symbol={paymentMethod.symbol}
              />
            )}
          </div>

          <FormPayment
            onSubmit={OnSubmitStaged}
            autoPredictionPrice={autoPredictionPrice}
            selectedPlan={selectedPlan}
            type={type}
            promoError={promoError}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          />
        </Content>
      ) : (
        <Content>
          <SuccessInfoStaged
            email={email!}
            devPlanHref={devPlanHref!}
            isAutoprediction={autoprediction}
            isStaged={staged}
          />
        </Content>
      )}
    </>
  );

  return (
    <>
      {showLoader && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}

      {sidebar === 'default' && (
        <SidebarWrapper>
          <Sidebar priceStart={minimumPrice} symbol={paymentMethod.symbol} />
        </SidebarWrapper>
      )}

      <ContentWrapper>{staged ? idpStageContent : idpContent}</ContentWrapper>
    </>
  );
};

const ContentWrapper = styled.div`
  height: 100vh;
  flex: 1;

  @media (max-width: 768px) {
    height: auto;
  }
`;

const SidebarWrapper = styled.div`
  width: 31.25rem;
  display: flex;
  justify-self: center;
  align-self: flex-start;
  height: 100vh;
  align-items: center;
  background: var(--sidebar-gradient);

  @media (max-width: 1320px) {
    overflow: unset !important;
    width: 100% !important;
    height: auto;
  }

  @media (max-width: 1280px) {
    width: 21.53rem;
    display: block;
    overflow: scroll;
    ::-webkit-scrollbar {
      width: 0px;
    }
  }
`;

const Content = styled.div<{ sidebar?: string; payedStatus?: string }>`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.sidebar !== 'default' &&
    css`
      overflow: inherit;
      height: auto;
    `}

  ${(props) =>
    props.payedStatus === 'success' &&
    props.sidebar !== 'default' &&
    css`
      margin-left: 3.5rem;
    `}

  @media (max-width: 1280px) {
    height: 100%;
    ::-webkit-scrollbar {
      width: 0px;
    }
  }

  @media (max-width: 768px) {
    width: 100vw !important;
  }
`;

const LoaderWrapper = styled.div`
  z-index: 2;
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
`;
